.header-gradient {
  background-color: #F4D03F;
  background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
}

.header-gradient-inverse {
  background-image: linear-gradient(312deg, #F4D03F 0%, #16A085 100%);
}

.image-select-background {
  background-image: radial-gradient(#444, #111);
}


/* pages/crop */

.guess-who-card {
  background: linear-gradient(90deg, rgba(223,53,53,1) 0%, rgba(255,0,0,1) 100%);
  width: 100px;
  height: 116.6px;
  padding: 6px;
}

.guess-who-card img {
  width: 100%;
  height: 73.92px;
  background-color: lightgrey;
}

.guess-who-card-name {
  background-color: white;
  height: 24px;
  margin-top: 6px;
  width: 100%;
  padding-top: 2px;
} 

@keyframes fadeOutToTop {
  from {
    opacity: 1;
    /* animation-timing-function: cubic-bezier(0.8, 0, 1, 1); */
  }
  to {
    transform: translateY(-25%);
    opacity: 0;
    /* animation-timing-function: cubic-bezier(0, 0, 0.2, 1); */
  }
}


@keyframes fadeIn {
  from {
    transform: translateY(25%);
    opacity: 0;
    /* animation-timing-function: cubic-bezier(0.8, 0, 1, 1); */
  }
  to {
    transform: translateY(0);
    opacity: 1;
    /* animation-timing-function: cubic-bezier(0, 0, 0.2, 1); */
  }
}

@keyframes fadeInFromTop {
  from {
    transform: translateY(-50px);
    opacity: 0;
    /* animation-timing-function: cubic-bezier(0.8, 0, 1, 1); */
  }
  to {
    transform: translateY(0);
    opacity: 1;
    /* animation-timing-function: cubic-bezier(0, 0, 0.2, 1); */
  }
}

@keyframes fadeInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 1;
    /* animation-timing-function: cubic-bezier(0.8, 0, 1, 1); */
  }
  to {
    transform: translateX(0);
    opacity: 1;
    /* animation-timing-function: cubic-bezier(0, 0, 0.2, 1); */
  }
}

